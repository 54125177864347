import { push } from 'connected-react-router';
import { useEffect, useState } from 'react';
import { Container } from 'typedi';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { useSelector } from 'app/hooks/store/use-selector.hook';
import { useDispatcher } from 'app/hooks/store/use-dispatcher.hook';
import { loginWithCredentials } from 'features/security/auth.actions';
import { AuthRepository } from 'app/repositories/auth.repository';
import { Page } from 'shared/components/analytics/page.component';
import { setLoginViewEvent } from 'features/analytics/redux/analytics-v2.actions';
import { useCookie } from 'app/hooks/use-cookie.hook';
import { Cookies } from 'app/globals/constants';
import { MigrationPhase } from 'shared/components/banner-post-login/use-okta-migration-banner-config';

import './login.component.scss';
import { LoginView, Views } from '@pages/login/Views';
import { getOktaConfig } from '@features/okta/config';
import AuthSwitcher from '@features/security/components/AuthSwitcher';
import { AuthAction, useAction } from '@features/datadog';
import { enableOktaForUser, isOkta } from 'utils/auth';

export interface ILoginPage {
 oktaUIEnabled?: boolean;
}

export const LoginPage = ({ oktaUIEnabled = false }: ILoginPage) => {
  const oneYearInMinutes = 525600;
  const [newAuth, setNewAuth, deleteNewAuth] = useCookie(Cookies.moAuth, oneYearInMinutes);
  const [hasOktaCreds, setHasOktaCreds] = useState(false);
  const { isOktaEnabled, oktaMigrationPhase } = useFlags();
  const trackAction = useAction();
  const isLegacyAuthenticated = useSelector(state => state.auth.isAuthenticated);

  const queryParams = new URLSearchParams(window.location.search);
  const loginError = useSelector(state => state.auth.errors);

  const dispatchLoginWithCredentials = useDispatcher(loginWithCredentials);
  const redirectToRegistration = useDispatcher(() => push('/registration'));
  const sendLoginViewEvent = useDispatcher(setLoginViewEvent);
  const auth = Container.get(AuthRepository);
  const [hideForm, setHideForm] = useState(isLegacyAuthenticated || auth.hasRemoteLoginCookie());

  const isOktaLoginOnly = oktaMigrationPhase === MigrationPhase.OKTA_LOGIN_ONLY;

  isOkta()
    .then(result => setHasOktaCreds(result))
    .catch(err => setHasOktaCreds(false));

  useEffect(() => {
    setHideForm(isLegacyAuthenticated || auth.hasRemoteLoginCookie());
  }, [isLegacyAuthenticated, auth.hasRemoteLoginCookie(), loginError]);

  /**
    * Deciding what form to show:
    * 'OKTA_WIDGET' | 'OKTA_REDIRECT' | 'HMAC'
    *
    * The default view is driven by the Okta LD flag, however while on the screen, you can
    *  update the url to select a new view as shown below.
    *  `'.../login?view=OKTA_WIDGET' | '.../login?view=OKTA_REDIRECT' | '.../login?view=HMAC'`
    * */
  const defaultView = ((isOktaEnabled || oktaUIEnabled) && hasOktaCreds && 'OKTA_WIDGET') || 'HMAC';

  // if migration phase is okta login only then view is okta_widget, else get the view from the querystring or assign the default
  const view = isOktaLoginOnly ?
    'OKTA_WIDGET'
    : (queryParams.get('view') || defaultView).toUpperCase() as LoginView;

 // when the view changes, update our cookies
 useEffect(() => {
  if (view === LoginView.HMAC || isLegacyAuthenticated) {
   localStorage.removeItem('okta-token-storage');
   deleteNewAuth();
   trackAction(AuthAction.LoginViewIsHmac);
  } else if (oktaUIEnabled || view === LoginView.OKTA_WIDGET || view === LoginView.OKTA_REDIRECT) {
   trackAction(AuthAction.LoginViewIsOkta);
   enableOktaForUser();
  }
  console.groupEnd();
 }, [view]);

 return (
  <Page name="Login">
   {Views[view]({
    dispatchLoginWithCredentials,
    redirectToRegistration,
    sendLoginViewEvent,
   })}
   {!isOktaLoginOnly && <AuthSwitcher />}
  </Page>
 );
};

export const Login = LoginPage;
