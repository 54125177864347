export const OktaWidgetStringOverrides = {
  'cs': {
    'primaryauth.title': ' ',
    'primaryauth.username.placeholder': 'E-mail',
    'error.username.required': 'Zadejte e-mailovou adresu.',
    'password.forgot.email.or.username.placeholder': 'Email',
    'password.forgot.email.or.username.tooltip': ' ',
    'error.auth.lockedOut' : 'Váš účet je uzamčen. Prosím resetujte své heslo pomocí odkazu Zapomenuté heslo níže.'
  },
  'de': {
    'primaryauth.title': ' ',
    'primaryauth.username.placeholder': 'E-mail',
    'error.username.required': 'Bitte geben Sie eine E-Mail-Adresse ein.',
    'password.forgot.email.or.username.placeholder': 'E-mail',
    'password.forgot.email.or.username.tooltip': ' ',
    'error.auth.lockedOut' : 'Ihr Konto ist gesperrt. Bitte setzen Sie Ihr Passwort über den Link „Passwort vergessen“ unten zurück.'
  },
  'en': {
    'primaryauth.title': ' ',
    'primaryauth.username.placeholder': 'Email',
    'error.username.required': 'Please enter an email address',
    'password.forgot.email.or.username.placeholder': 'Email',
    'password.forgot.email.or.username.tooltip': ' ',
    'error.auth.lockedOut' : 'Your account is locked. Please reset your password using the Forgot password link below.'
  },
  'es': {
    'primaryauth.title': ' ',
    'primaryauth.username.placeholder': 'E-mail',
    'error.username.required': 'Indique una dirección de e-mail',
    'password.forgot.email.or.username.placeholder': 'E-mail',
    'password.forgot.email.or.username.tooltip': ' ',
    'error.auth.lockedOut' : 'Tu cuenta esta bloqueada. Restablezca su contraseña utilizando el enlace ¿Olvidé mi contraseña? a continuación.'
  },
  'fr': {
    'primaryauth.title': ' ',
    'primaryauth.username.placeholder': 'Courriel',
    'error.username.required': 'Veuillez saisir une adresse de courriel valide',
    'password.forgot.email.or.username.placeholder': 'Courriel',
    'password.forgot.email.or.username.tooltip': ' ',
    'error.auth.lockedOut' : 'Ton compte est bloqué. Veuillez réinitialiser votre mot de passe en utilisant le lien Mot de passe oublié ci-dessous.'
  },
  'hu': {
    'primaryauth.title': ' ',
    'primaryauth.username.placeholder': 'E-mail',
    'error.username.required': 'Adjon meg egy e-mail címet',
    'password.forgot.email.or.username.placeholder': 'E-mail',
    'password.forgot.email.or.username.tooltip': ' ',
    'error.auth.lockedOut' : 'Fiókja zárolva van. Kérjük, állítsa vissza jelszavát az alábbi Elfelejtett jelszó hivatkozás segítségével.'
  },
  'it': {
    'primaryauth.title': ' ',
    'primaryauth.username.placeholder': 'E-mail',
    'error.username.required': 'Immettere un indirizzo e-mail valido',
    'password.forgot.email.or.username.placeholder': 'E-mail',
    'password.forgot.email.or.username.tooltip': ' ',
    'error.auth.lockedOut' : 'Il tuo account è bloccato. Reimposta la tua password utilizzando il link Password dimenticata riportato di seguito.'
  },
  'ja': {
    'primaryauth.title': ' ',
    'primaryauth.username.placeholder': '電子メール',
    'error.username.required': '電子メールアドレスを入力してください',
    'password.forgot.email.or.username.placeholder': '電子メール',
    'password.forgot.email.or.username.tooltip': ' ',
    'error.auth.lockedOut' : 'あなたのアカウントはロックされています。以下の「パスワードを忘れた場合」リンクを使用してパスワードをリセットしてください。'
  },
  'ko': {
    'primaryauth.title': ' ',
    'primaryauth.username.placeholder': '이메일',
    'error.username.required': '이메일 주소를 입력해 주세요',
    'password.forgot.email.or.username.placeholder': '이메일',
    'password.forgot.email.or.username.tooltip': ' ',
    'error.auth.lockedOut' : '귀하의 계정이 잠겨 있습니다. 아래 비밀번호 찾기 링크를 이용하여 비밀번호를 재설정해주세요.'
  },
  'nl-NL': {
    'primaryauth.title': ' ',
    'primaryauth.username.placeholder': 'E-mail',
    'error.username.required': 'Voer een e-mailadres in',
    'password.forgot.email.or.username.placeholder': 'E-mail',
    'password.forgot.email.or.username.tooltip': ' ',
    'error.auth.lockedOut' : 'Je account is geblokkeerd. Reset uw wachtwoord via de onderstaande link Wachtwoord vergeten.'
  },
  'pl': {
    'primaryauth.title': ' ',
    'primaryauth.username.placeholder': 'E-mail',
    'error.username.required': 'Wprowadź adres e-mail',
    'password.forgot.email.or.username.placeholder': 'E-mail',
    'password.forgot.email.or.username.tooltip': ' ',
    'error.auth.lockedOut' : 'Twoje konto jest zablokowane. Zresetuj swoje hasło, korzystając z poniższego linku Zapomniałem hasła.'
  },
  'pt-BR': {
    'primaryauth.title': ' ',
    'primaryauth.username.placeholder': 'Email',
    'error.username.required': 'Insira um endereço de e-mail',
    'password.forgot.email.or.username.placeholder': 'Email',
    'password.forgot.email.or.username.tooltip': ' ',
    'error.auth.lockedOut' : 'Sua conta está bloqueada. Por favor, redefina sua senha usando o link Esqueci a senha abaixo.'
  },
  'ro': {
    'primaryauth.title': ' ',
    'primaryauth.username.placeholder': 'E-mail',
    'error.username.required': 'Vă rugăm introduceți o adresă de e-mail',
    'password.forgot.email.or.username.placeholder': 'E-mail',
    'password.forgot.email.or.username.tooltip': ' ',
    'error.auth.lockedOut' : 'Contul tău este blocat. Vă rugăm să vă resetați parola folosind linkul Am uitat parola de mai jos.'
  },
  'ru': {
    'primaryauth.title': ' ',
    'primaryauth.username.placeholder': 'Эл. почта',
    'error.username.required': 'Введите адрес эл. почты.',
    'password.forgot.email.or.username.placeholder': 'Эл. почта',
    'password.forgot.email.or.username.tooltip': ' ',
    'error.auth.lockedOut' : 'Ваш аккаунт заблокирован. Пожалуйста, сбросьте свой пароль, используя ссылку «Забыли пароль» ниже.'
  },
  'tr': {
    'primaryauth.title': ' ',
    'primaryauth.username.placeholder': 'E-posta',
    'error.username.required': 'Lütfen bir e-posta adresi girin',
    'password.forgot.email.or.username.placeholder': 'E-posta',
    'password.forgot.email.or.username.tooltip': ' ',
    'error.auth.lockedOut' : 'Hesabınız kilitli. Lütfen aşağıdaki Şifremi unuttum bağlantısını kullanarak şifrenizi sıfırlayın.'
  },
  'zh-CN': {
    'primaryauth.title': ' ',
    'primaryauth.username.placeholder': '电邮',
    'error.username.required': '请输入电子邮件地址',
    'password.forgot.email.or.username.placeholder': '电邮',
    'password.forgot.email.or.username.tooltip': ' ',
    'error.auth.lockedOut' : '您的帐户已被锁定。请使用下面的忘记密码链接重置您的密码。'
  },
  'zh-TW': {
    'primaryauth.title': ' ',
    'primaryauth.username.placeholder': '電郵',
    'error.username.required': '請輸入電子郵寄地址',
    'password.forgot.email.or.username.placeholder': '電郵',
    'password.forgot.email.or.username.tooltip': ' ',
    'error.auth.lockedOut' : '您的帳戶已被鎖定。請使用下面的忘記密碼連結重設您的密碼。'
  }
}
